export enum Size {
	Tiny = ".1rem",
	Small = ".5rem",
	Medium = "1rem",
	Large = "2rem",
	Huge = "4rem",
}

export enum Gap {
	Tiny = "gap-tiny",
	Small = "gap-small",
	Medium = "gap-medium",
	Large = "gap-large",
	Huge = "gap-huge",
}

export enum Padding {
	Tiny = "padding-tiny",
	Small = "padding-small",
	Medium = "padding-medium",
	Large = "padding-large",
	Huge = "padding-huge",
}

export type PaddingRect = Readonly<{
	top?: Padding;
	right?: Padding;
	bottom?: Padding;
	left?: Padding;
	vertical?: Padding;
	horizontal?: Padding;
}>;

export type CSSLength = string;

export type Constraints = Readonly<{
	/** Force an exact  main axis size for this layout widget.
	 * For a row this would be its width, for a column its height.
	 */
	mainAxisSize?: CSSLength;

	/** The minimal main axis size of this layout widget.
	 * For a row this would be its width, for a column its height.
	 */
	minMainAxisSize?: CSSLength;

	/** The maximal main axis size of this layout widget.
	 * For a row this would be its width, for a column its height.
	 */
	maxMainAxisSize?: CSSLength;

	/** Force an exact cross axis size for this layout widget.
	 * For a row this would be its height, for a column its width.
	 */
	crossAxisSize?: CSSLength;

	/** The minimal cross axis size of this layout widget.
	 * For a row this would be its height, for a column its width.
	 */
	minCrossAxisSize?: CSSLength;

	/** The maximal cross axis size of this layout widget.
	 * For a row this would be its height, for a column its width.
	 */
	maxCrossAxisSize?: CSSLength;
}>;

type Falsy = 0 | "" | null | undefined | false | 0n;
export type Classes = string | Falsy | Classes[];
export const classes = (classes: Classes) =>
	[classes]
		.flat(Infinity as 1)
		.filter(Boolean)
		.join(" ")
		.replace(/\s+/, " ");
