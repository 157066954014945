import React, { ReactNode } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

type Route = Readonly<{
	url: string;
	translateKey: string;
	component: React.LazyExoticComponent<any>;
	authorised?: () => boolean;
	subroutes?: Route[];
}>;

export const routes: Route[] = [
	{
		url: "/",
		translateKey: "home",
		component: React.lazy(() => import("pages/home/home-page")),
	},
];

const routeToJsx = (route: Route): ReactNode =>
	route.subroutes?.length ? (
		[
			<Route
				key={route.translateKey}
				path={route.url}
				element={<route.component />}
			/>,
			...route.subroutes.map(routeToJsx),
		]
	) : (
		<Route
			key={route.translateKey}
			path={route.url}
			element={<route.component />}
		/>
	);

export const renderRoutes = (routes: Route[]) => (
	<BrowserRouter>
		<Routes>
			{routes.map(routeToJsx)}
			<Route path="*" element={<span>404: Not Found</span>} />
		</Routes>
	</BrowserRouter>
);
