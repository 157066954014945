import { ReactComponent as Logo } from "assets/icons/logo.svg";
import Column from "layout/column";
import { CrossAxisAlignment } from "layout/flex";
import { Gap, Padding } from "layout/layout";
import Row from "layout/row";
import useWindowSize from "lib/window-size";
import Icon from "../icon/icon";
import { ReactComponent as MedocanLogo } from "assets/images/medocan-logo-blue.svg";
import "./header.scss";

const Header = () => {
	const size = useWindowSize();
	const isSmallScreen = size === "small";
	const isNormalScreen = size === "normal";

	return (
		<Row classes="header" as="header" gap={Gap.Medium} padding={Padding.Large}>
			<Row gap={Gap.Medium} crossAxisAlignment={CrossAxisAlignment.Center}>
				<MedocanLogo />
			</Row>
		</Row>
	);
};

export default Header;
