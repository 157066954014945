import Header from "components/header/header";
import Link from "components/link/link";
import Flex, { MainAxisAlignment } from "layout/flex";
import { Padding } from "layout/layout";
import Row from "layout/row";
import "lib/i18n";
import Prefetch from "lib/prefetch";
import { renderRoutes, routes } from "lib/router";
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";

const App = () => {
	return (
		<>
			<Header />
			<Flex as="main" grow>
				{renderRoutes(routes)}
			</Flex>
			<Row
				as="footer"
				padding={{ horizontal: Padding.Huge, vertical: Padding.Small }}
				mainAxisAlignment={MainAxisAlignment.End}>
				<a
					href="https://www.freepik.com/vectors/doctor-icon%27%3EDoctor"
					target="_blank"
					rel="noopener noreferrer">
					Icon vector created by rawpixel.com - www.freepik.com
				</a>
			</Row>
		</>
	);
};

ReactDOM.render(
	<React.StrictMode>
		<Prefetch
			loading={<span>Splash screen</span>}
			error={error => <div>{error.toString()}</div>}>
			<App />
		</Prefetch>
	</React.StrictMode>,
	document.getElementById("root")
);
