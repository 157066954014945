import { Component, ReactNode, Suspense } from "react";

type LoadingState = Readonly<{
	progress: number;
}>;

type PrefetchProps = Readonly<{
	loading: ((state?: LoadingState) => ReactNode) | ReactNode;
	error: ((error: Error) => ReactNode) | ReactNode;
	children: ReactNode;
}>;

type PrefetchState = Readonly<{
	error?: Error;
}>;

class Prefetch extends Component<PrefetchProps, PrefetchState> {
	static getDerivedStateFromError(error: any) {
		return { error };
	}

	state: PrefetchState = {};

	render() {
		return this.state.error ? (
			typeof this.props.error === "function" ? (
				this.props.error(this.state.error)
			) : (
				this.props.error
			)
		) : (
			// TODO: Allow suspense to report progress in `loading`.
			<Suspense
				fallback={
					typeof this.props.loading === "function"
						? this.props.loading()
						: (this.props.loading as any)
				}>
				{this.props.children}
			</Suspense>
		);
	}
}

export default Prefetch;
