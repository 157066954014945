/** A function that logs a value to the console.
 *
 * Useful for dropping in-place in some arbitrary expressions.
 */
export const log = <T>(value: T) => {
	console.log(value);
	return value;
};

/** Returns a Promise that resolves to _nothing_ after _at least_ `ms` milliseconds. */
export const wait = (ms: number) =>
	new Promise<void>(resolve => setTimeout(resolve, ms));

export const debounce = (ms: number) => {
	let timeout: number | undefined;
	return <T extends (...args: any[]) => any>(f: T) =>
		((...params: Parameters<T>) => {
			window.clearTimeout(timeout);
			timeout = window.setTimeout(f.bind(null, ...params), ms);
		}) as T;
};
