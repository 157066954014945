import { useEffect, useMemo, useState } from "react";
import { debounce } from "./utils";

export type WindowSize = "insane" | "large" | "normal" | "small";

const useWindowSize = (): WindowSize => {
	const [width, setWidth] = useState(window.innerWidth);

	const updateSize = useMemo(
		() => debounce(250)(() => setWidth(window.innerWidth)),
		[]
	);

	useEffect(() => {
		window.addEventListener("resize", updateSize);

		updateSize();

		return () => window.removeEventListener("resize", updateSize);
	}, []);

	return width > 1280
		? "insane"
		: width > 1050
		? "large"
		: width > 768
		? "normal"
		: "small";
};

export default useWindowSize;
