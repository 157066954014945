import {
	Classes,
	classes,
	Constraints,
	CSSLength,
	Gap,
	Padding,
	PaddingRect,
} from "layout/layout";
import React, { ReactNode } from "react";

export type Theme = "white" | "green" | "orange";

export enum FlexDirection {
	Row = "row",
	Column = "column",
}

export enum MainAxisAlignment {
	Start = "axis-main-flex-start",
	Center = "axis-main-center",
	End = "axis-main-flex-end",
	SpaceBetween = "axis-main-space-between",
}

export enum CrossAxisAlignment {
	Start = "axis-cross-flex-start",
	Center = "axis-cross-center",
	End = "axis-cross-flex-end",
	Stretch = "axis-cross-stretch",
	Baseline = "axis-cross-baseline",
}

export enum WrapAlignment {
	Start = "wrap-flex-start",
	Center = "wrap-center",
	End = "wrap-flex-end",
	Stretch = "wrap-stretch",
	Baseline = "wrap-baseline",
	SpaceBetween = "wrap-space-between",
}

export type FlexProps = Readonly<{
	children?: ReactNode;

	/** TODO: Documentation */
	direction?: FlexDirection;

	/** TODO: Documentation */
	wrap?: true;

	/** TODO: Documentation */
	grow?: true | number;

	/** TODO: Documentation */
	shrink?: true | number;

	/** TODO: Documentation */
	mainAxisAlignment?: MainAxisAlignment;

	/** TODO: Documentation */
	crossAxisAlignment?: CrossAxisAlignment;

	/** TODO: Documentation */
	wrapAlignment?: WrapAlignment;

	/** TODO: Documentation */
	gap?: Gap;

	/** TODO: Documentation */
	as?: keyof HTMLElementTagNameMap;

	/** TODO: Documentation */
	classes?: Classes;

	/** TODO: Documentation */
	padding?: Padding | PaddingRect;

	/** TODO: Documentation */
	theme?: Theme;

	/** TODO: Documentation */
	constraints?: Constraints;

	/** The initial main axis size of this layout widget before
	 * distributing leftover space between itself and its siblings.
	 */
	basis?: CSSLength;

	style?: React.CSSProperties;
}>;

const calcConstraint = (
	props: FlexProps,
	mainValue?: CSSLength,
	crossValue?: CSSLength
) => (props.direction === "row" ? mainValue : crossValue);

export const makePadding = (paddingRect: PaddingRect = {}): Padding[] => {
	let padding = [];
	const sides = ["top", "right", "bottom", "left"] as const;

	if (paddingRect.vertical)
		["top", "bottom"].forEach(side =>
			padding.push(paddingRect.vertical!.split("-").join(`-${side}-`))
		);
	if (paddingRect.horizontal)
		["left", "right"].forEach(side =>
			padding.push(paddingRect.horizontal!.split("-").join(`-${side}-`))
		);
	for (const side of sides) {
		if (paddingRect[side])
			padding.push(paddingRect[side]!.split("-").join(`-${side}-`));
	}

	return padding as Padding[];
};

const Flex = ({ children, as, ...props }: FlexProps) =>
	React.createElement(as ?? "div", {
		style:
			props.grow || props.shrink || props.basis || props.constraints
				? {
						flexGrow: props.grow !== undefined ? Number(props.grow) : undefined,
						flexShrink:
							props.shrink !== undefined ? Number(props.shrink) : undefined,
						flexBasis: props.basis,
						width:
							calcConstraint(
								props,
								props.constraints?.mainAxisSize,
								props.constraints?.crossAxisSize
							) ?? props.style?.width,
						height:
							calcConstraint(
								props,
								props.constraints?.crossAxisSize,
								props.constraints?.mainAxisSize
							) ?? props.style?.height,
						minWidth:
							calcConstraint(
								props,
								props.constraints?.minMainAxisSize,
								props.constraints?.minCrossAxisSize
							) ?? props.style?.minWidth,
						maxWidth:
							calcConstraint(
								props,
								props.constraints?.maxMainAxisSize,
								props.constraints?.maxCrossAxisSize
							) ?? props.style?.maxWidth,
						minHeight:
							calcConstraint(
								props,
								props.constraints?.minCrossAxisSize,
								props.constraints?.minMainAxisSize
							) ?? props.style?.minHeight,
						maxHeight:
							calcConstraint(
								props,
								props.constraints?.maxCrossAxisSize,
								props.constraints?.maxMainAxisSize
							) ?? props.style?.maxHeight,
						...props.style,
				  }
				: undefined,

		className: classes([
			"flex",
			props.direction,
			props.wrap && "wrap",
			props.gap,
			props.mainAxisAlignment,
			props.crossAxisAlignment,
			props.wrapAlignment,
			props.classes,
			props.theme && `themes ${props.theme}-theme`,
			typeof props.padding === "string"
				? props.padding
				: makePadding(props.padding),
		]),
		children,
	});

export default Flex;
